#root{
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

html,
body {
    overflow: hidden;
}

body{
  background: url(../public/img/proton-bg.jpg) no-repeat center/cover fixed;
}

.page-wrapper{
    position: absolute;
    overflow: auto;
    overflow-x: hidden;
    top: 0;left: 0;
    width: 100%;
    height: calc(100vh - 75px);
    padding-bottom: 15px;
}